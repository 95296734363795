<template>
  <div>
    <div class="w-100 pa-4">
      <div class="font-weight-bold primaryColor">
        Reports
        <v-progress-circular
          class="ml-2"
          indeterminate
          v-if="loading"
          size="18"
          :width="2"
          color="black"
        >
        </v-progress-circular>
      </div>
      <div class="pt-3 d-flex flex-wrap justify-space-between">
        <div>
          <v-slide-group v-model="tabSelect" mandatory>
            <v-slide-item
              v-for="n in $store.state.brokerName == 'Aliceblue'
                ? alicebluetabs
                : abmltabs"
              :key="n"
              v-slot="{ active, toggle }"
              :value="n"
            >
              <span
                @click="
                  $router
                    .push({ path: 'reports', query: { tab: n } })
                    .catch(() => {})
                "
              >
                <v-btn
                  :title="n"
                  depressed
                  height="26"
                  class="fsize12 text-capitalize mr-2"
                  @click="toggle"
                  :color="active ? 'primary' : 'grey lighten-3'"
                  >{{ n }}</v-btn
                >
              </span>
            </v-slide-item>
          </v-slide-group>
        </div>
      </div>
    </div>
    <v-divider class="mx-4"></v-divider>
    <div v-if="tabSelect == 'Counts'">
      <exchCounts />
    </div>
    <div v-else>
      <v-card class="ma-4 cardBoxShadow rounded-lg">
        <holdingsList v-if="tabSelect == 'Holdings'" />
        <positionAvgList v-if="tabSelect == 'Positions'" />
        <edisReport v-if="tabSelect == 'EDiS'" />
        <settlemntDetails v-if="tabSelect == 'Settlement Info'" />
        <payin v-if="tabSelect == 'Razorpay payment'" />
        <payment v-if="tabSelect == 'Hdfc payment'" />
        <mwList v-if="tabSelect == 'Market Watch'" />
        <bankDetail v-if="tabSelect == 'Bank Details'" />
        <feedbacks v-if="tabSelect == 'User Feedbacks'" />
        <hazelCast v-if="tabSelect == 'Hazelcast'" />
        <userStatus v-if="tabSelect == 'User Status'" />
      </v-card>
      <v-card v-if="tabSelect == 'Orderslist'" class="mx-4">
        <v-chart class="chart" autoresize :options="getList" />
      </v-card>
    </div>
  </div>
</template>
<script>
import VChart from "vue-echarts";
import "@/plugins/echart";
import { mapGetters } from "vuex";
import commonFunc from "../../mixins/commonFunctions";
export default {
  mixins: [commonFunc],
  components: {
    holdingsList: () => import("../contents/holdingsList.vue"),
    positionAvgList: () => import("../contents/positionAvgList.vue"),
    // edisList: () => import("../contents/edisList.vue"),
    edisReport: () => import("../kambalaReports/edis.vue"),
    payin: () => import("./payIn.vue"),
    payout: () => import("./payOut.vue"),
    payment: () => import("../kambalaReports/payment.vue"),
    hazelCast: () => import("./hazelcast"),
    settlemntDetails: () => import("../contents/settlementInfo.vue"),
    // countList: () => import("../Counts.vue"),
    exchCounts: () => import("../kambalaReports/exch-counts.vue"),
    mwList: () => import("../contents/mwList.vue"),
    bankDetail: () => import("../contents/bankDetails.vue"),
    feedbacks: () => import("./feedbacks.vue"),
    hazelCast: () => import("./hazelcast"),
    userStatus: () => import("./userStatus.vue"),
    VChart,
  },
  data: () => ({
    // alicebluetabs: ["Holdings", "Positions", "EDiS", "Settlement Info", "Razorpay payment",],
    alicebluetabs: [
      "Holdings",
      "Positions",
      "Counts",
      "EDiS",
      "Razorpay payment",
      "Hdfc payment",
      "Market Watch",
      "Bank Details",
      "User Feedbacks",
      "Hazelcast",
      "User Status",
    ],
    abmltabs: ["EDiS", "Razorpay payment"],
    tabSelect: "Payin",
  }),
  computed: {
    ...mapGetters({
      loading: "getLoader",
    }),
    ...mapGetters({
      getList: "getUserCount",
    }),
  },
  created() {
    this.tabSelect = this.$route.query.tab ? this.$route.query.tab : "Payin";
 
  },

  mounted() {
    // this.$store.dispatch('getPayOffData')
  },
  methods: {},
};
</script>
